import { Box, Container, Flex, Heading, Spacer } from "@chakra-ui/react"
import berries from "../../../../Assets/berriesP.jpg"
import harold from "../../../../Assets/haroldP.jpg"
import white_flowers from "../../../../Assets/white_flowersP.jpg"
import { useResponsiveAttributes } from "../../../../Hooks"
import { colors } from "../../../../StylingConstants"

type PictureData = {
	picture: string
	text: string
	textColor: string
	textPosition: number
}

const pictureData: PictureData[] = [
	{
		picture: white_flowers,
		text: "Garden design and development",
		textColor: "#A3899C",
		textPosition: 2,
	},
	{
		picture: berries,
		text: "Build edible ecosystems",
		textColor: "#EB803B",
		textPosition: 1,
	},
	{
		picture: harold,
		text: "Make a wildlife garden",
		textColor: colors.theriaGreen,
		textPosition: 0,
	},
]

export const TriplePicture = () => {
	const { isCollapsed } = useResponsiveAttributes()

	return (
		<Flex
			maxW={"100dvw"}
			minH={isCollapsed ? "0" : "100dvh"}
			p={4}
			flexDirection={isCollapsed ? "column" : "row"}
		>
			{pictureData.map((picture, index) => {
				return (
					<Container
						bgImage={picture.picture}
						bgColor={colors.theriaGreen}
						bgSize={"cover"}
						maxW={"100%"}
						minH={isCollapsed ? "300px" : "100%"}
						borderRadius={"3xl"}
						mr={index < pictureData.length - 1 ? 8 : 0}
						mb={
							index < pictureData.length - 1 && isCollapsed
								? 4
								: 0
						}
						key={index}
						p={4}
					>
						<Flex flexDirection={"column"} h={"100%"}>
							{picture.textPosition === 1 ||
							picture.textPosition === 2 ? (
								<Spacer />
							) : null}
							<Box
								bg={colors.pageBackground}
								opacity={"90%"}
								borderRadius={"xl"}
								p={4}
								textAlign={"center"}
							>
								<Heading
									color={picture.textColor}
									fontSize={"3xl"}
								>
									{picture.text}
								</Heading>
							</Box>
							{picture.textPosition === 1 ? <Spacer /> : null}
						</Flex>
					</Container>
				)
			})}
		</Flex>
	)
}
