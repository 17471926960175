import { Button, Flex, Stack, Box, Center, Spacer } from "@chakra-ui/react"
import { colors } from "../../StylingConstants"
import haroldRotated from "../../Assets/harold_rotatedP.jpg"
import { useState } from "react"
import { useResponsiveAttributes } from "../../Hooks"
import {
	BlurbInformation,
	Blurb,
	BigPictureBackground,
} from "../../TheriaComponents"

const aboutInformationList: BlurbInformation[] = [
	{
		name: "Our Purpose",
		dotPoints: [
			"Reconnect people with food, flora and fauna",
			"Promote biodiversity support",
			"Expore and create with nature",
			"Empower and educate communities",
		],
	},
	{
		name: "Our Principles",
		dotPoints: [
			"Work with nature, not against it",
			"Support local, community projects",
			"Use natural resources wisely",
			"Create with nature in mind",
		],
	},
	{
		name: "Our Process",
		dotPoints: [
			"Assess your ecosystem and identify it's needs",
			"Design a custom plan together",
			"Plant and build features in your ecosystem",
			"Maintain and care for your ecosystem",
			"Teach you how to improve your ecosystem over time",
		],
	},
	{
		name: "Our People",
		dotPoints: [
			"Our team includes passionate gardeners and environmentalists",
			"We have experts in sustainable practices and scientists",
			"We're dedicated to teaching and community engagement",
			"We focus on keeping nature healthy and diverse",
		],
	},
]

export const AboutUs = () => {
	const [activeTab, setActiveTab] = useState(0)

	const { isCollapsed } = useResponsiveAttributes()
	return (
		<BigPictureBackground image={haroldRotated} padBelowNavigationBar>
			<Flex
				minW={"100%"}
				minH={"100%"}
				p={4}
				borderRadius={"3xl"}
				flexDirection={isCollapsed ? "column" : "row"}
			>
				<Center>
					<Stack direction={"column"}>
						{aboutInformationList.map((service, index) => (
							<Button
								borderRadius={"xl"}
								key={index}
								mt={index === 0 ? 0 : "auto"}
								mb={
									index === aboutInformationList.length - 1
										? 0
										: "auto"
								}
								bgColor={
									activeTab === index
										? colors.theriaGreen
										: colors.pageBackground
								}
								color={
									activeTab === index
										? colors.pageBackground
										: colors.theriaGreen
								}
								_hover={{
									bgColor: colors.theriaGreen,
									color: colors.pageBackground,
								}}
								opacity={"90%"}
								onClick={() => {
									setActiveTab(index)
								}}
								maxWidth={"md"}
							>
								{service.name}
							</Button>
						))}
					</Stack>
				</Center>
				<Spacer />
				<Center w={"100%"}>
					<Box
						bgColor={"white"}
						borderRadius={"xl"}
						opacity={"90%"}
						p={isCollapsed ? 2 : 8}
						mt={isCollapsed ? 2 : 0}
						h="fit-content"
					>
						<Blurb information={aboutInformationList[activeTab]} />
					</Box>
				</Center>
				<Spacer />
			</Flex>
		</BigPictureBackground>
	)
}
