import { Body } from "./Body"
import { Footer } from "./Footer"
import { Outlet, useLocation } from "react-router-dom"
import { useLayoutEffect } from "react"
import { HomePage } from "./HomePage"
import { Container, Box } from "@chakra-ui/react"
import { colors, MAX_CONTENT_WIDTHS } from "../../StylingConstants"
import { TheriaNavbar } from "./NavigationBar/TheriaNavbar"

export const Home = () => {
	const location = useLocation()

	//INFO: This makes the page scroll to the top when the route changes
	useLayoutEffect(() => {
		window.scrollTo(0, 0)
	})

	return (
		<Box p={0} bgColor={colors.pageBackground}>
			<Container p={0} maxW={MAX_CONTENT_WIDTHS.THERIA_MAX_WIDTH}>
				{/* <NavigationBar navigationItems={NAVIGATION_SET} /> */}
				<TheriaNavbar />
				<Body>
					{location.pathname === "/" ? <HomePage /> : <Outlet />}
				</Body>
				<Footer />
			</Container>
		</Box>
	)
}
