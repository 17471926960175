import { ReactNode } from "react"
import { Container } from "@chakra-ui/react"

export const Body = ({ children }: { children?: ReactNode }) => {
	return (
		<Container maxW={"100%"} minH={"92vh"} p={0} m={0}>
			{children}
		</Container>
	)
}
