import {
	Box,
	Button,
	Center,
	Flex,
	Heading,
	Image,
	Stack,
	useMediaQuery,
	Menu,
	MenuButton,
	IconButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react"

import { HamburgerIcon } from "@chakra-ui/icons"
import { Link as ReactRouterLink, useLocation } from "react-router-dom"
import therialogo from "../../../Assets/theria-logo.png"
import { colors } from "../../../StylingConstants"
import { getParentUrl } from "../../BasePage/Helpers"

type NavigationItem = { title: string; url: string }

const navigationSet: NavigationItem[] = [
	{ title: "Services", url: "/services" },
	{ title: "About", url: "/about-us" },
	{ title: "Resources", url: "/resources" },
]

const DesktopNavbar = () => {
	const location = useLocation()

	return (
		<Box
			borderRadius={"3xl"}
			color={colors.theriaDarkText}
			position={"fixed"}
			top={8}
			ml={8}
			bg={"rgba(255,255,255,0.9)"}
			p={2}
			zIndex={1}
		>
			<Stack direction={"row"}>
				<Box>
					<Flex flexDirection={"row"}>
						<Image src={therialogo} boxSize={10} mr={2}></Image>
						<Center>
							<Button
								variant={"link"}
								as={ReactRouterLink}
								color={colors.theriaGreen}
								_hover={{}}
								to={"/"}
							>
								<Heading
									color={colors.theriaGreen}
									fontSize={"2xl"}
								>
									Theria
								</Heading>
							</Button>
						</Center>
					</Flex>
				</Box>
				{navigationSet.map((navItem, index) => (
					<Center key={index}>
						<Button
							variant={"ghost"}
							as={ReactRouterLink}
							color={colors.theriaGreen}
							fontSize={"xl"}
							borderRadius={"xl"}
							_hover={{
								fontSize: "2xl",
							}}
							to={navItem.url}
							fontWeight={
								navItem.url === getParentUrl(location.pathname)
									? "bold"
									: "semibold"
							}
						>
							{navItem.title}
						</Button>
					</Center>
				))}
				<Center>
					<Button
						bgColor={colors.pageBackground}
						opacity={"100%"}
						to="/contact"
						as={ReactRouterLink}
						color={colors.theriaGreen}
						borderRadius={"3xl"}
						fontSize={"xl"}
						_hover={{
							fontSize: "2xl",
						}}
						fontWeight={
							"/contact" === getParentUrl(location.pathname)
								? "bold"
								: "semibold"
						}
					>
						Contact
					</Button>
				</Center>
			</Stack>
		</Box>
	)
}

const MobileNavbar = () => {
	const location = useLocation()
	return (
		<Box
			borderRadius={"3xl"}
			color={colors.theriaDarkText}
			position={"fixed"}
			top={8}
			ml={8}
			bg={colors.pageBackground}
			opacity={"90%"}
			p={2}
			zIndex={1}
		>
			<Stack direction={"row"} spacing={8}>
				<Menu>
					<Center>
						<MenuButton
							as={IconButton}
							aria-label="Options"
							icon={
								<HamburgerIcon
									boxSize={8}
									m={1}
									color={colors.theriaDarkText}
								/>
							}
							variant="ghost"
							justifySelf="flex-start"
						/>
					</Center>

					<MenuList bgColor={colors.pageBackground}>
						{navigationSet.map((navigationItem) => (
							<MenuItem
								as={ReactRouterLink}
								to={navigationItem.url}
								key={navigationItem.title}
								bgColor={colors.pageBackground}
								fontSize="lg"
								_hover={{
									bgColor: colors.theriaGreen,
									color: colors.pageBackground,
								}}
								fontWeight={
									navigationItem.url ===
									getParentUrl(location.pathname)
										? "bold"
										: "semibold"
								}
							>
								{navigationItem.title}
							</MenuItem>
						))}
						<MenuItem
							as={ReactRouterLink}
							to="/contact"
							bgColor={colors.pageBackground}
							fontSize="lg"
							_hover={{
								bgColor: colors.theriaGreen,
								color: colors.pageBackground,
							}}
							fontWeight={
								"/contact" === getParentUrl(location.pathname)
									? "bold"
									: "semibold"
							}
						>
							Contact
						</MenuItem>
					</MenuList>
				</Menu>
				<Box>
					<Flex flexDirection={"row"}>
						<Center mr={8}>
							<Button
								variant={"link"}
								as={ReactRouterLink}
								color={colors.theriaGreen}
								_hover={{
									fontSize: "2xl",
								}}
								to={"/"}
							>
								<Heading color={colors.theriaGreen}>
									Theria
								</Heading>
							</Button>
						</Center>
						<Image src={therialogo} boxSize={10}></Image>
					</Flex>
				</Box>
			</Stack>
		</Box>
	)
}

export const TheriaNavbar = () => {
	const [isLargerThan768] = useMediaQuery("(min-width: 768px)")
	return isLargerThan768 ? <DesktopNavbar /> : <MobileNavbar />
}
