import {
    Button,
    Container,
    UnorderedList,
    ListItem,
    Center,
    Heading,
    useMediaQuery,
} from '@chakra-ui/react'
import { colors } from '../StylingConstants'
import { Link as ReactRouterLink } from 'react-router-dom'

export type BlurbInformation = {
    name: string
    dotPoints: string[]
}

export const Blurb = ({ information }: { information: BlurbInformation }) => {
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
    return (
        <Container
            textAlign={'left'}
            fontSize={'2xl'}
            color={colors.theriaDarkText}
        >
            <Heading
                mt={2}
                mb={4}
                color={colors.theriaGreen}
                textAlign={'center'}
                fontSize={isLargerThan768 ? '3xl' : 'xl'}
            >
                {information.name}
            </Heading>
            <UnorderedList mb={8}>
                {information.dotPoints.map((point, index) => (
                    <ListItem
                        key={index}
                        fontSize={isLargerThan768 ? 'xl' : 'md'}
                    >
                        {point}
                    </ListItem>
                ))}
            </UnorderedList>
            <Center>
                <Button
                    as={ReactRouterLink}
                    to={'/contact'}
                    borderRadius={'xl'}
                    bgColor={colors.theriaGreen}
                    color={colors.pageBackground}
                    fontSize={'xl'}
                    _hover={{
                        fontSize: '2xl',
                    }}
                >
                    Contact
                </Button>
            </Center>
        </Container>
    )
}
