import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ChakraProvider } from "@chakra-ui/react"
import { theme } from "../StylingConstants"
import { TheriaPageNotFound } from "../Pages"
import {
	TheriaServices,
	AboutUs,
	TheriaContact,
	TheriaResources,
} from "../Pages"
import { Home } from "../Pages/Home"

export const App = () => {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <Home />,
			children: [
				{
					path: "/resources",
					element: <TheriaResources />,
				},
				{
					path: "/services",
					element: <TheriaServices />,
				},
				{
					path: "/about-us",
					element: <AboutUs />,
				},
				{
					path: "/contact",
					element: <TheriaContact />,
				},
				{ path: "*", element: <TheriaPageNotFound /> },
			],
		},
	])

	return (
		<ChakraProvider theme={theme}>
			<RouterProvider router={router} />
		</ChakraProvider>
	)
}
