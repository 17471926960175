import { Project } from "../OurWorkTypes"
import { GiBeerStein, GiPaintBrush, GiGuitar } from "react-icons/gi"

export const PROJECTS: Project[] = [
	{
		title: "UX/Graphic Designer Portfolio Site",
		tags: ["Static Websites", "Custom Services", "Cloud Deployments"],
		description:
			"Jayson Yan is a talented UX/Graphics Designer who needed a new portfolio website to demonstrate his work. This Static Website was developed for him by us, using his designs. Jayson is a busy man so we added a custom contact form that would send him a text message when it was filled out. This way he could know instantly when someone wanted to contact him about his services and wouldn't have to check some database or email. We deployed it all for him on the Cloud linked it to his pre-existing domain.",
		imageUrl: "",
		path: "",
		icon: GiPaintBrush,
		websiteUrl: "https://www.jaysonyan.com",
	},
	{
		title: "Buy Me A Drink",
		tags: ["Payment Capable Websites", "Cloud Deployments"],
		description:
			"Buy me a drink is simple website we made for influencer Annie Hexmouth so her fans can shout her a beverage. We hooked her up with a domain and hosted it for her with the AWS Cloud. She hasn't paid for her own beer in months.",
		imageUrl: "",
		path: "",
		icon: GiBeerStein,
	},
	{
		title: "Band Merchandise Webstore",
		tags: ["Full Stack Websites", "Custom Services", "Cloud Deployments"],
		description:
			"Seven String Deathdrop is a 4 woman metal band from Athens, Greece. With a global tour looming, they needed a website to sell t-shirts to their rapidly growing, global fanbase. We developed a Full Stack Website for them, including a database to keep track of orders and customers. We also added an automatic email service to notify customers when their order is shipped and delivered. We hosted it on GCP, as they expressed a preference for that cloud provider.",
		imageUrl: "",
		path: "",
		icon: GiGuitar,
	},
]
