import { Heading, Box, Flex, Spacer, Container } from "@chakra-ui/react"
import { colors } from "../../../../StylingConstants"
import { useState, useEffect } from "react"
import taytay from "../../../../Assets/taytayP.jpg"
import { BigPictureBackground } from "../../../../TheriaComponents"
import TextTransition, { presets } from "react-text-transition"
import haroldRotated from "../../../../Assets/harold_rotatedP.jpg"
import whiteFlowersRotated from "../../../../Assets/white_flowers_rotatedP.jpg"
import whiteFlowers from "../../../../Assets/white_flowersP.jpg"
import birdsOnLawnmower from "../../../../Assets/birdsP.jpg"
import yelloFlowers from "../../../../Assets/cute_flowersP.jpg"
import greenBerries from "../../../../Assets/green_berriesP.jpg"
import berries from "../../../../Assets/berriesP.jpg"
import harold from "../../../../Assets/haroldP.jpg"

export const Landing = () => {
	const [index, setIndex] = useState(0)

	useEffect(() => {
		const intervalId = setInterval(
			() => setIndex((index) => index + 1),
			3000
		)
		return () => clearTimeout(intervalId)
	}, [])

	const transformations: string[][] = [
		["Grocery", "Stores"],
		["Wildlife", "Sanctuaries"],
		["Balanced", "Ecosystems"],
		["Indigenous", "Havens"],
	]
	return (
		<BigPictureBackground image={taytay}>
			<Flex flexDirection={"column"} h={"100%"}>
				<Spacer />
				<Box
					bg={colors.pageBackground}
					opacity={"90%"}
					borderRadius={"xl"}
					p={[2, 4]}
					textAlign={"center"}
					w={"fit-content"}
					maxW={"100%"}
					ml={"auto"}
					minH={"fit-content"}
				>
					<Heading color={colors.theriaDarkText}>
						Transforming gardens into
					</Heading>
					<TextTransition
						inline={true}
						springConfig={presets.wobbly}
						direction="down"
					>
						<Heading
							color={colors.theriaGreen}
							textAlign={"center"}
							flexWrap={"wrap"}
						>
							{transformations[index % transformations.length][0]}
						</Heading>
						<Heading
							color={colors.theriaGreen}
							textAlign={"center"}
							flexWrap={"wrap"}
						>
							{transformations[index % transformations.length][1]}
						</Heading>
					</TextTransition>
				</Box>
			</Flex>
			{/**below is a hack for loading the pictures from the other pages**/}
			<Container bgImage={haroldRotated}>
				<Container bgImage={whiteFlowersRotated}></Container>
				<Container bgImage={birdsOnLawnmower}></Container>
				<Container bgImage={yelloFlowers}></Container>
				<Container bgImage={greenBerries}></Container>
				<Container bgImage={berries}></Container>
				<Container bgImage={harold}></Container>
				<Container bgImage={whiteFlowers}></Container>
			</Container>
		</BigPictureBackground>
	)
}
