import { colors } from "../../../StylingConstants"
import { Flex, Spacer, Text, Button, Box } from "@chakra-ui/react"
import { useResponsiveAttributes } from "../../../Hooks"
import { Link as ReactRouterLink } from "react-router-dom"

type FooterLink = { text: string; url: string; internal: boolean }

export const TheriaFooter = () => {
	const { isCollapsed } = useResponsiveAttributes()

	const footerLinks: FooterLink[] = [
		{ text: "SERVICES", url: "/services", internal: true },
		{ text: "ABOUT", url: "/about-us", internal: true },
		{ text: "RESOURCES", url: "/resources", internal: true },
		{ text: "CONTACT", url: "/contact", internal: true },
		{
			text: "FACEBOOK",
			url: "https://www.facebook.com/profile.php?id=61553917755636",
			internal: false,
		},
		{
			text: "INSTAGRAM",
			url: "https://www.instagram.com/theria_gardening/",
			internal: false,
		},
		// { text: 'TERMS & CONDITIONS', url: '/', internal: true },
		// { text: 'PRIVACY POLICY', url: '/', internal: true },
		{ text: "© 2024 THERIA", url: "/", internal: true },
	]
	return (
		<Flex
			maxW={"100dvw"}
			bg={colors.pageBackground}
			p={4}
			color={colors.theriaDarkText}
			flexDirection={isCollapsed ? "column" : "row"}
		>
			<Box maxW={isCollapsed ? "100%" : "60%"} mb={4} fontSize={"lg"}>
				<Text mb={4}>
					Theria Gardening respectfully acknowledges the Traditional
					Custodians of the land on which we operate, the{" "}
					<b> Wurundjeri Woi-wurrung </b>people. We pay our respects
					to their Elders past, present, and emerging, and extend that
					respect to all Aboriginal and Torres Strait Islander peoples
					today.
				</Text>
				<Text>
					We recognise the deep spiritual connection and the
					relationship that Aboriginal and Torres Strait Islander
					people have to Country. As we work in the field of ecosystem
					gardening, we are constantly reminded of the importance of
					the land, not just as a physical space, but as a source of
					identity, connection, and sustenance. We commit to learning
					from and listening to the wisdom of the Traditional Owners
					and to conduct our business in a manner that respects and
					honours the Indigenous heritage of this land.
				</Text>
			</Box>

			<Spacer />
			<Flex
				flexDirection={isCollapsed ? "row" : "column"}
				justifyContent={"center"}
				flexWrap={"wrap"}
			>
				{footerLinks.map((link, index) => (
					<Button
						key={index}
						variant={"link"}
						as={ReactRouterLink}
						to={link.url}
						color={colors.theriaDarkText}
						w={"fit-content"}
						alignSelf={"flex-end"}
						mb={4}
						target={link.internal ? "_self" : "_blank"}
						mr={isCollapsed ? 4 : 0}
					>
						{link.text}
					</Button>
				))}
			</Flex>
		</Flex>
	)
}
