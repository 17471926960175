import { Container, Center, Stack, Heading, Link } from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { colors } from "../../StylingConstants"
import birdsOnLawnmower from "../../Assets/birdsP.jpg"
import { BigPictureBackground } from "../../TheriaComponents"

export const TheriaPageNotFound = () => {
	return (
		<BigPictureBackground image={birdsOnLawnmower}>
			<Center w={"100%"} minH={"100%"}>
				<Container
					bgColor={colors.pageBackground}
					opacity={"90%"}
					borderRadius={"xl"}
					p={4}
				>
					<Stack>
						<Heading
							color={colors.theriaGreen}
							textAlign={"center"}
						>
							Page not found...
						</Heading>
						<Link
							as={ReactRouterLink}
							to="/"
							color={colors.theriaDarkText}
							textAlign={"center"}
							fontSize={"2xl"}
							fontWeight={"semibold"}
						>
							Go home
						</Link>
					</Stack>
				</Container>
			</Center>
		</BigPictureBackground>
	)
}
