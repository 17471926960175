import { ReactNode } from "react"
import { Container } from "@chakra-ui/react"
import { colors } from "../StylingConstants"
// import { useState, useEffect } from "react"

export const BigPictureBackground = ({
	children,
	image,
	padBelowNavigationBar,
}: {
	children?: ReactNode
	image: string
	padBelowNavigationBar?: boolean
}) => {
	// const [imageLoaded, setImageLoaded] = useState(false)
	// var img = new Image()
	// img.src = image
	// useEffect(() => {
	// 	var int = setInterval(() => {
	// 		if (img.complete) {
	// 			clearInterval(int)
	// 			setImageLoaded(true)
	// 		}
	// 	}, 50)
	// }, [img.complete])

	return (
		<Container maxW={"100%"} minH={"100svh"} p={4} display={"flex"}>
			<Container
				maxW={"100%"}
				bgColor={colors.theriaGreen}
				bgImage={image}
				bgSize={"cover"}
				minH={"100%"}
				borderRadius={"3xl"}
				p={4}
				pt={padBelowNavigationBar ? "88px" : 4}
			>
				{children}
			</Container>
		</Container>
	)
}
