import { Text, Box, Stack, Heading, Button, Center } from "@chakra-ui/react"
import greenBerries from "../../Assets/green_berriesP.jpg"
import { colors } from "../../StylingConstants"
import { useResponsiveAttributes } from "../../Hooks"
import { BigPictureBackground } from "../../TheriaComponents"
import { Link as ReactRouterLink } from "react-router-dom"

export const TheriaResources = () => {
	const { isCollapsed } = useResponsiveAttributes()
	return (
		<BigPictureBackground image={greenBerries}>
			<Center minH={"100%"}>
				<Box
					bg={colors.pageBackground}
					opacity={"90%"}
					borderRadius={"xl"}
					p={isCollapsed ? 2 : 8}
					textAlign={"center"}
					maxW={isCollapsed ? "100%" : "50%"}
				>
					<Stack color={colors.theriaDarkText} spacing={4}>
						<Heading color={colors.theriaGreen}>
							Join our Substack
						</Heading>
						<Text fontSize={"lg"}>
							Dive deeper into the world of sustainable gardening
							with Theria. Subscribe to our Substack for regular
							insights, stories, and tips on creating and
							maintaining your very own ecosystem garden. Let's
							grow together!
						</Text>
						<Center>
							<Button
								bgColor={colors.theriaGreen}
								color={colors.pageBackground}
								borderRadius={"xl"}
								fontSize={"xl"}
								as={ReactRouterLink}
								to={"https://theria.substack.com/"}
								target="_blank"
								_hover={{
									fontSize: "2xl",
								}}
								mb={2}
							>
								Subscribe
							</Button>
						</Center>
					</Stack>
				</Box>
			</Center>
		</BigPictureBackground>
	)
}
