export const MAX_CONTENT_WIDTHS = {
	MIDDLE_FOCUSED_CONTENT: "6xl",
	THERIA_MAX_WIDTH: "2000px",
}

export const GRID_NO_SIDES = "0px 1fr 0px"

export const GRID_STRETCHY_MIDDLE = "210px 1fr 210px"

export const GRID_STRETCHY_SIDES = "1fr 992px 1fr"

export const GRID_SWITCH_POINT = 1452

export const COLLAPSE_WIDTH = 992
