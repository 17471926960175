import { Container, Stack, Center, Heading, Text, Link } from "@chakra-ui/react"
import yellowFlowers from "../../Assets/cute_flowersP.jpg"
import { colors } from "../../StylingConstants"
import { BigPictureBackground } from "../../TheriaComponents"

export const TheriaContact = () => {
	return (
		<BigPictureBackground image={yellowFlowers}>
			<Center w={"100%"} minH={"100%"}>
				<Container
					bgColor={colors.pageBackground}
					opacity={"90%"}
					borderRadius={"xl"}
					p={4}
				>
					<Stack>
						<Heading
							color={colors.theriaGreen}
							textAlign={"center"}
						>
							Get in touch!
						</Heading>
						<Text color={colors.theriaDarkText} fontSize={"xl"}>
							<b>Email:</b>{" "}
							<Link href="mailto:theriagardening@gmail.com">
								theriagardening@gmail.com
							</Link>
						</Text>
						<Text color={colors.theriaDarkText} fontSize={"xl"}>
							<b>Mobile: </b>{" "}
							<Link href="tel:+61411589059">
								{"(+61)"} 411 589 059
							</Link>
						</Text>
					</Stack>
				</Container>
			</Center>
		</BigPictureBackground>
	)
}
