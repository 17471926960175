import { Container } from '@chakra-ui/react'
import { Landing, TriplePicture, SubstackSubscription } from './Sections'

export const HomePage = () => {
    return (
        <Container maxW="100%" p={0}>
            <Landing />
            <TriplePicture />
            <SubstackSubscription />
        </Container>
    )
}
